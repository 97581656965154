<template>
    <div>
        <div v-if="companiesGroups && Object.keys(companiesGroups).length > 0">
            <div v-for="(g, index) in Object.keys(companiesGroups)" :key="g._id">
                <section v-if="companiesGroups[g].companies.length > 0" class="section d-flex companies-list-area ptb_100" :class="bgClasses[index % bgClasses.length]">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col pt_25 px-4 px-sm-0">
                                <h1>{{ companiesGroups[g][lang] }}</h1>
                            </div>
                        </div>
                        <div class="row ptb_50 companies-list">
                            <div class="col-xl-3 col-6 my-auto p-4 text-center pb_25" v-for="c in companiesGroups[g].companies" :key="c._id">
                                <router-link :to="'/companies/' + c.slug">
                                    <img :src="baseUrl + c.logo.url" alt="">
                                </router-link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div v-if="!companiesGroups || Object.keys(companiesGroups).length === 0">
            <section class="companies-list-area ptb_100 bg-dark-blue">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12" v-for="index in 8" :key="index">
                            <div class=" ph-item">
                                <div class="py-4">
                                    <div class="ph-col-4 big my-4"></div>
                                    <div class="ph-col-10 big my-4"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            baseUrl: process.env.VUE_APP_STRAPI_URI,
            bgClasses: ['bg-dark-blue',  'bg-classic-blue', 'bg-dark-blue', 'bg-dark-gray']
        };
    },
    computed: { 
        ...mapState(['lang', 'companies', 'lifecycles']),
        companiesGroups() {
            var groups = {};
            
            for (var i = 0; i < this.lifecycles.length; i++) {
                groups[this.lifecycles[i].en] = {
                    en: this.lifecycles[i].en,
                    hun: this.lifecycles[i].hun,
                    companies: []
                }

                var c = [];

                for (var j = 0; j < this.companies.length; j++) {
                    //skip if hidden
                    if (this.companies[j].displayPriority && this.companies[j].displayPriority < 0) {
                        continue;
                    }
                    if (this.lifecycles[i].en === this.companies[j].lifecycle.en) {
                        c.push(this.companies[j]);
                    }
                }

                let sorted = c.slice().sort((a, b) => b.displayPriority - a.displayPriority);

                groups[this.lifecycles[i].en].companies = sorted;
            }

            return groups;
        }
    }
}
</script>